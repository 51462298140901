// font-family
$monospace: monospace;
$open-sans: Open Sans;

$black: #000;
$white: #fff;
$dialog-background: rgba(58, 67, 104, .85);
$dark-gray: #3b3b3b;
$background: #f4f7f9;
$bali-hai: #8f9fb3;
$blue-ly: #4c70ff;
$light-gray: #e2e2e2;
$font-light-gray: #696969;
$font-light-desc: #797979;
$shadow-gray: #c5c5c5;
$font-gray: #a8a8a8;
$label-font: #545454;
$link-blue: #3b54ff;
$input-border: #e1e3e6;
$error-font: #ff1e1e;
$error-background: #ffc6c6;
$error-border: #ff9595;
$success-background: #5aca8f;
$success-border: #34b973;
$button-gray: #545454;
$button-blue: #4273f8;
$skyblue: #00a6da;
$cornflower-blue: #6f89ed;
$dashed-color: #e1e1e1;
$descrip-color: #b7b7b7;
$blue-border: #9aafff;
$blue-border-selector: #4b6efa;
$font-tag: #ececec;
$box-shadow: #dcdcdc;
$back-link-gray: #9b9b9b;
$time-font-gray: #2d2d2d;
$time-font-light-gray: #707070;
$dashed-color: #e1e1e1;
$lighten-gray: #e9edef;
$disabled-background: #fbfbfb;
$font-desc: #848484;
$hr: #d2d8db;
$placeholder: #a9a9a9;
$doc-descrip: #939393;
$dodger-blue: #3ab9fd;
$dodger-blue-two: #487df4;
// Just as example for the SettingsNavbar
$red: red;
$error-msg: #ee5657;
$blue-term: #344fc0;
$light-gray: #e6e6e6;
$red-danger: #ff5959;
$red-dark: #ff2929;
$green-mint: #23a561;
$caption-grey: #858585;
$dialog-caption: #454545;
$trial-table: #dedede;
$failed-red: #d0021b;
$failed-grey: #f5f5f5;
$status-grey: #ccc;
// revamp
$cerulean: #0698e7;
$mercury: #e4e4e4;
$dark-sky-blue: #249ce0;
$dark-sky-blue-three: #2d9cec;
$dark-sky-blue-five: #3d88df;
$dark-sky-blue-fourteen: #5392e6;
$dark-sky-blue-fifteen: #39a3e2;
$battleship-grey: #717c88;
$lynch: #657f9c;
$azure: #0895ea;
$azure-three: #1f99eb;
$box-shadow: #c8c8c8;
$azure-two: #0093e9;
$periwinkle-blue: #9db0f4;
$periwinkle-gray: #c7d6ea;
$danube: #6f94db;
$bluish: #2184ce;
$bluish-two: #317dd5;
$azure-90: #1396ea;
$picton-blue: #3c9eed;
$dark: #1b2734;
$pale-grey-three: #e6e8eb;
$warm-grey-two: #747474;
$gray: #898989;
$mystic: #eaeff2;
$alabaster: #f9f9f9;
$warm-grey-tree: #9f9f9f;
$warm-grey-three: #9f9f9f;
$white-three: #fdfdfd;
$duck-egg-blue: #cfe7fb;
$cornflower-blue: #678cd7;
$white-four: #d8d8d8;
$pinkish-grey: #bebebe;
$black-two: #3a3a3a;
$greyish-brown-two: #3e3e3e;
$pale-grey-four: #f6f8f9;
$white-six: #dadada;
$greyish-brown-three: #545148;
$social-btn: #0aa3dc;
$dark-sky-blue-two: #279ce1;
$greyish-brown-four: #494949;
$list-hover: #f2f2f2;
$dark-sky-blue-six: #3aa1de;
$pale-grey-five: #ecf2f4;
$turquoise-blue-two: #02a5da;
$warm-grey-five: #888;
$warm-grey-six: #9a9a9a;
$pinkish-grey-two: #cecece;
$green-teal: #34b973;
$dark-mint-two: #41c35b;
$greyish-two: #a4a4a4;
$water-blue: #1a9fde;
$pinkish-grey-three: #bfbfbf;
$greyish-three: #b0b0b0;
$brownish-grey: #5a5a5a;
$slate: #536475;
$dark-sky-blue-seven: #34b6e0;
$button-green: #32d189;
$silver-three: #eaebec;
$blue-grey: #7c93a0;
$silver-four: #dde2e4;
$greyish-four: #aaa;
$very-light-blue: #edf5ff;
$warm-grey-seven: #727272;
$pinkish-grey-four: #c6c6c6;
$greyish-five: #ababab;
$warm-grey-eight: #969696;
$dark-sky-blue-eight: #57afd9;
$charcoal-grey: #3c4651;
$turquoise-blue-three: #03a6db;
$grey-cancel-subscription: #b4b4b4;
$yellow-shade: #ffffed;
$white-seven: #eaeaea;
$pastel-red: #e16160;
$cloudy-blue: #bac7cf;
$seaweed-two: #1fcf7f;
$blush: #ed7474;
$greyish-four: #aaa;
$slate-two: #434e66;
$bluey-grey: #8a95ad;
$dark-sky-blue-ten: #29b3e0;
$light-grey: #f2f4f4;
$white-eight: #dbdbdb;
$pale-grey-six: #f3f6f9;
$botticelli: #c7d9e6;
$silver-two: #ced0da;
$pale-grey: #f2f4f8;
$ice: #effff5;
$ice-blue: #feffff;
$tomato: #f33f3f;
$brownish-grey-three: #6d6d6d;
$white-nine: #fafafa;
$silver: #c3c3c3;
$bittersweet: #ff716d;
$pale-red: #ffa09d;
$purple: #6f89ed;
$green-cyan: #32d089;
$lightish-green-4: rgba(129, 221, 108, .04);
$warm-grey-nine: #737373;
$lightish-green: #81dd6c;
$salmon: #ff7f7b;
$iron: #d1d4d8;
$white-ten: #e9e9e9;
$soft-blue-three: #7477ed;
$aqua-blue: #0ddbe5;
$mid-blue: #2166c4;
$transparent: rgba(255, 255, 255, 0);
$charcoal-grey-two: #3f434d;
$warm-grey-ten: #8c8c8c;
$cloudy-blue-two: #d6dde4;
$green-blue-two: #03cf87;
$green-blue-four: #05ba90;
$water-blue-five: #0a89d5;
$light-greenish-blue: #4af9c6;
$light-olive: #9fcf5a;
$lightish-green-three: #6ae081;
$lightish-green-four: #6dd96c;
$aqua: #1ae2c3;
$aquamarine: #03d59d;
$aqua-green: #1ee98c;
$aqua-marine-two: #48d2be;
$pale-grey-seven: #f7fafb;
$cerulean: #0687df;
$slate-grey: #595d6f;
$slate-grey-two: #555862;
$slate-green: #617367;
$duck-egg-blue-tw: #c4ecf2;
$azure-five: #00a2e2;
$steel: #7d8a98;
$bright-sky-blue: #07c7f3;
$azul: #1574e4;
$bright-sea-green: #0eff9e;
$bright-sky-blue-two: #11caf3;
$water-blue-six: #1979da;
$dark-sky-blue-eleven: #209ce9;
$azure-six: #1a98eb;
$bluey-grey-two: #8594a8;
$tundor: #4d4d4d;
$codgray: #141414;
$silver-five: #d1d7da;
$mineshaft: #2a2a2a;
$pale-grey-eight: #f7f9fb;
$cool-blue: #2887d8;
$dark-sky-blue-twelve: #369dec;
$light-blue: #8bcaff;
$cerulean-two: #006ebd;
$ocean-blue: #006cb9;
$cerulean-three: #0374c5;
$sky-blue: #64b4f7;
$lightish-green-two: #63d379;
$dark-sky-blue-thirteen: #419ce4;
$light-seafoam-green: #aeffb0;
$light-mint-green: #adf6af;
$bluish-three: #207fca;
$pale-grey-nine: #f5f6fa;
$very-light-blue-two: #e4e8f3;
$dark-blue-grey: #19243c;
$pale-grey-ten: #fdfeff;
$bluey-grey-three: #8b93a7;
$salmon-two: #ff716d;
$salmon-three: #ff7c78;
$pale: #ffdacd;
$light-periwinkle: #d5dce6;
$pale-grey-eleven: #f8fafc;
$pale-grey-thirteen: #f9fbfe;
$charcoal-grey-three: #333c48;
$yellow-orange: #f6a600;
$pale-grey-twelve: #f5f8fc;
$brown-grey: #999;
$abbey: #45494c;
$pastel-green: #4adf77;
$turquoise-blue: #00a6da;
$azure-seven: #0698e7;
$dark-blue: #4c5977;
$dark-desaturated-blue: #343d53;
$link-water: #cedff5;
$zircon: #f6faff;
$polo-blue: #81a2cb;
$gallery: #eee;
$mandy: #e5625e;
$maize: #edc65f;
$cream-brulee: #ffdf9c;
$old-lace: #fff5e1;
$water-blue-seven: #0da3dc;
$very-light-pink: #e6e6e6;
$azure-eight: #2bb4e0;
$water-blue-eight: #1697ea;
$greyish-blue: #5f7d9c;
$blood-orange: #ef4e00;
$pale-grey-fourteen: #ebeef0;
$brown-gray: #999;
$very-light-pink-two: #f0f0f0;
$very-light-pink-four: #fff1f1;
$very-light-pink-five: #ffe4e4;
$deep-gray: #666;
$dark-sky-blue-four: #4992e6;
$dark-salmon: #c65656;
$discount-green: #39ca7e;
$price-grey: #5c5c5c;
$grapefruit: #ff5756;
$turquoise-blue-four: #05a4db;
$new-plan-blue: #449fe5;
$old-plan-grey: #909090;
$old-plan-border-grey: #eeeff0;
$funnel-sidebar-blue: #033986;
$brown-grey-two: #a6a6a6;
$progress-navigator-blue: #0056d0;
$save-caribbean-green: #00c999;
$save-next-spring-green: #00e67d;
$caribbean-green-light: #00c094;
$caribbean-green-dark: #06d979;
$pale-grey-fifteen: #f0f2f5;
$bluey-grey-four: #9ea0a5;
$pale-grey-sixteen: #ecf4ff;
$powder-blue: #bad6f9;
$charcoal-grey-four: #363840;
$congress-blue: #084290;
$input-border-2: #dae2f6;
$white-lilac: #e5e8f4;
$dusk-two: #485e7d;
$light-grey-blue: #a3aecb;
$drawer-background-end: rgba(8, 66, 144, .41);
$drawer-background-start: rgba(8, 66, 144, 0);
$blue-1: #06225c;
$blue-2: #04489e;
$blue-3: rgba(35, 38, 71, .5);
$blue-4: #033986;
$blue-5: #35a9ec;
$blue-6: #003f8e;
$blue-7: #4493fa;
$blue-8: #00327b;
$funnel-sidebar-blue: #033986;
$funnel-form-border: #dae2f6;
$funnel-error-background: #fff9f9;
$funnel-error-border: #ffd0d0;
$gradient-green-dark: #00c999;
$gradient-green-light: #00e67d;
$gradient-green-hover: #33ef9d;
$steel-two: #6c7794;
$funnel-link-hover: #4885f5;
$darkish-blue: #033986;
$brownish-grey-four: #666;
$brownish-grey-six: #736161;
$light-periwinkle-two: #dae2f6;
$light-periwinkle-three: #dbe3f0;
$light-grey-blue: #a3aecb;
$light-silver: #dde1f2;
$warning-title-blue: #022558;
$warning-content-grey: #555;
$warning-highlight-blue: #114e9e;
$warning-button-grey: #cfcfcf;
$requirement-grey: #626366;
$download-link-green: #03db78;
$fake-browser-grey: #dde4f0;
$fake-browser-border: #d1d7e3;
$fake-browser-button-red: #fe7065;
$fake-browser-button-yellow: #ffc718;
$fake-browser-button-green: #2cd14a;
$scanning-background-blue: #1c5ab6;
$scan-report-description: #4e4e4e;
$scan-report-check-point: #636363;
$scan-report-try-another-light: #49bffe;
$scan-report-try-another-dark: #405ff6;
$bluey-grey-five: #919da5;
$green1: #7ed321;
$black1: #333;
$black2: #112643;
$black3: #182f50;
$gray1: #a5bcca;
$gray2: rgba(98, 114, 158, .28);
$gray3: rgba(108, 119, 148, .7);
$gray4: #e7e8ed;
$gray5: rgba(197, 197, 197, .5);
$gray6: #868e96;
$purple1: #8771cd;
$light-blue: #b7e3ff;
$orange1: #ff6a6a;
$azure-nine: #239eeb;
$green-1: #00ce94;
$red-1: #fb7676;
$pale-grey-seventeen: #f2f3f8;
$very-light-pink-four: #fff1f1;
$warm-grey-eleven: #a97d7d;
$azure-nine: #239eeb;
$brown-grey-three: #7b7b7b;
$soft-blue-four: #67b7e6;
$sick-green: #c4bb32;
$sickly-yellow: #ccca35;
$light-orange: #efa651;
$very-light-blue-three: #e7ecf0;
$salmon-four: #f58080;
$salmon-five: #f56f6f;
$light-olive-green: #a0c640;
$light-olive-green-two: #9dcb56;
$light-olive-two: #c9d655;
$soft-green: #7cc173;
$greyish-teal: #56bda6;
$not-compliant-red: #e05656;
$progress-bar-bg: #133671;
$progress-bar-inner: #25fee3;
$progress-bar-outer: #4393d9;
$scan-result-grey: #f3f8ff;
$scan-result-title: #113166;
$scan-result-subtitle: #73849f;
$summary-footer: #a3a3a3;
$summary-th-border: #efefef;
$summary-th-color: #c9c9c9;
$summary-pass-color: #53b27d;
$summary-fail-bg: #fff6f6;
$summary-pass-bg: #f2fff5;
$scan-report-blue-start: #1d5bb7;
$scan-report-blue-end: #002151;
$mosaic-grey: #fcfcfc;
$website-icon-green: #5ef1c8;
$white-two: rgba(226, 226, 226, .5);
$chart-bg: #f7f9ff;
$chart-title: #a8adbd;
$chart-line: #c4c4c4;
$high-risk-bar: #fd7b7b;
$low-risk-bar: #a6a8f4;
$navigation-active: rgba(255, 255, 255, 0.5);
$dark-peach: #ed6d6d;
$add-cookie-blue: #00a5dc;
$cookie-link-blue: #4273f8;
$pale-grey: #f2f4fb;
$dusk: #424d6b;
$green-blue: #00d48e;
$not-published-background: #fee8e8;
$new-doc-title: #013986;
$radio-button-blue: #2ca4ee;
$radio-button-white: #edf1f2;
$radio-button-black: #465a6e;
$radio-button-green: #66be65;
$radio-button-red: #ff6c63;
$radio-button-purple: #2e67b1;
$jeans-blue: #013986;
$very-light-pink: #fff6f5;
$light-peach: #fecdc7;
$pink-border: #ffbcbc;
$subdomain-label-background: #f6f9fd;
$choose-plan: #436dff;
$choose-plan-hover: #6b8eff;
$choose-plan-active: #3b58b6;
$choose-plan-disable: #bebebe;
$pricing-testing-footer-announce: #a5a4ad;
$pricing-testing-compliance-solution: #3a4564;
$pricing-testing-table-border: #ededef;
$pricing-testing-blue-background: #4672ff;
$pricing-testing-plan-label: #282828;
$pricing-testing-plan-title: #1f1e33;
$pricing-testing-plan-info: #6c7794;
$pricing-testing-most-popular-background: #01c89a;
$pricing-testing-used-by-background: #1f1d34;
$pricing-testing-tool-tip-border: #e6e6e9;
$dodger-blue: #2bb1ff;
$format-border: #e3ecf5;
$format-title: #4f5c6e;
$format-selected-blue-one: #37a9e6;
$format-selected-blue-two: #3f59d8;
$format-comparison-background: #f4f8fc;
$inner-circle-border: #ebebeb;
$consent-logs-success-message: rgba(6, 152, 231, .12);

$content-max-width: 120rem;
$content-min-width: 80rem;

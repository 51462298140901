@import 'stylesheets/variables';
@import 'stylesheets/mixins';
@import 'stylesheets/animations';

.list-style {
  list-style-type: none;
  padding: 0 0.875rem;
  margin: 0;
  text-align: left;
  min-width: 9.375rem;

  li {
    padding: 0.875rem 0.5625rem 0.375rem;
    white-space: nowrap;
    cursor: pointer;
    color: $warm-grey-two;
    font-size: 0.8125rem;

    &:hover {
      color: $skyblue;
    }

    &:last-child {
      padding: 0.875rem 0.5625rem;
    }
  }

  .header-item {
    padding: 0.875rem 0;
  }

  .link-item {
    padding: 0;
  }

  .user-item {
    padding: 0.875rem 0.5625rem;
    font-weight: 500;
    line-height: 1.5;
    border-bottom: 0.0625rem solid $pale-grey-three;
    text-decoration: none;
    display: block;
    color: $warm-grey-two;

    small {
      display: block;
      font-size: 0.75rem;
      color: $font-light-desc;
      font-weight: normal;
    }

    &:hover {
      color: $skyblue;
    }
  }
}

.icon-container {
  float: right;
  animation: fadeInSlow 2s;
  position: relative;
  top: -2.3125rem;

  .green-icon {
    @include setIcon(1.875rem, 1.875rem, 'images/success-check.svg');

    margin: 0;
    outline: none;
  }

  .loading-icon {
    @include setIcon(1.875rem, 1.875rem, 'images/loading.gif');

    margin: 0;
    outline: none;
    background-size: 5rem;
  }
}

.icon {
  position: relative;
  cursor: pointer;
  border: none;
}

.red-icon {
  @include setIcon(1.875rem, 1.875rem, 'images/red-warning.svg');

  margin: 0;
  outline: none;
}

.list-style-website {
  @extend .list-style;

  padding: 0.625rem 1.25rem;

  li {
    @include setFont('Open Sans', 0.75rem, $dark-blue-grey, 600);

    padding: 0.625rem 0;

    &:last-child {
      padding: 0.625rem 0;
    }

    &.list-item-delete {
      color: $salmon-three;
      margin-top: 0.625rem;
      padding: 1.25rem 0 0.625rem;
      border-top: 0.0625rem solid $very-light-blue-two;
    }
  }
}

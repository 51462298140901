@import 'stylesheets/variables';


.root {
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: $background;
}

.content {
  max-width: 49.75rem;
  margin: 2.1875rem auto;
  background-color: $white;
  border: solid 0.0625rem $lighten-gray;
  border-radius: 0.1875rem;
  box-shadow: 0 0.125rem 0.375rem 0 rgba($box-shadow, .5);
}

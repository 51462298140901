$link-hover-color: #4672ff;
$link-color: #363840;

.root {
  position: absolute;
  z-index: 10;
  top: calc(100% + 0.3125rem);
  right: 0;
  --link-color: #{ $link-color };
  --link-hover-color: #{ $link-hover-color };
}

.content {
  box-sizing: border-box;
  padding: 0.625rem 1rem 0.375rem;
  box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 50, 123, 0.1);
  background: #fff;
  border: 0.0625rem solid #ced4da;
  font-size: 0.875rem;
  border-radius: 0.1875rem;
}

.accountInfo {
  border-bottom: 0.0625rem solid #cbd1d7;
}

.linkGroups {
  &:not(:empty) {
    border-bottom: 1px solid #cbd1d7;
  }
  white-space: nowrap;
}

.linkTitle {
  font-weight: 500;
  color: #99a0a7;
  margin: 0;
  text-transform: uppercase;
}

%msg-base {
  padding: 1.25rem 0.8125rem;
  text-align: left;
  font-size: 0.75rem;
  margin: 0 0 1.875rem;
  border-radius: 0.1875rem;
  line-height: 1.4;
}

.error {
  @extend %msg-base;

  color: #ff1e1e;
  background-color: #ffc6c6;
  border: solid 0.0625rem #ff9595;
}

.light {
  @extend %msg-base;

  color: #ff1e1e;
  padding: 0.8125rem 1.25rem;
  background-color: #fff9f9;
  border: solid 0.0625rem #ffd0d0;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(255, 208, 208, .54);
}

.success {
  @extend %msg-base;

  color: #fff;
  background-color: #5aca8f;
  border: solid 0.0625rem #34b973;
}

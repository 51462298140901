@import 'stylesheets/mixins.scss';
@import 'stylesheets/variables.scss';

.container {
  position: relative;
}

.button {
  width:         100%;
  height:        2.5625rem;
  padding:       0 0.9375rem;
  box-sizing:    border-box;
  border:        solid 0.0625rem $input-border;
  border-radius: 0.1875rem;
  background:    url(images/triangle-2.svg) center right 0.9375rem no-repeat;
  outline:       none;
  appearance:    none;
  display:       flex;
  align-items:   center;

  &:focus {
    border-color: $skyblue;
  }

  &::-ms-expand,
  &::-ms-clear {
    display: none;
  }
}

.text {
  @include setFont(Roboto, 0.8125rem, $dusk-two, 500);
}

.color-circle {
  width:         1.3125rem;
  height:        1.3125rem;
  border-radius: 50%;
  background:    $white;
  border:        0.0625rem solid $input-border;
  display:       block;
  margin-right:  0.5rem;
}

.color-picker-wrapper {
  position: absolute;
  z-index:  2;
}

.dismiss-mask {
  position: fixed;
  left:     0;
  right:    0;
  top:      0;
  bottom:   0;
}

.text {
  white-space: nowrap;
}

.button {
  color: #00c999 !important;
  font-size: 1rem !important;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 1rem;
}

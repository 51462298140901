@import 'stylesheets/variables';


$icon-width: 1.875rem;
$icon-height: $icon-width;

.root {
  align-items: center;
  background: #363840;
  border-radius: 50%;
  border: 0.0625rem solid #ced4da;
  color: #fff;
  display: flex;
  font-size: 0.75rem;
  font-weight: 500;
  height: $icon-height;
  justify-content: center;
  overflow: hidden;
  text-transform: uppercase;
  width: $icon-width;
}

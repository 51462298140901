@import 'stylesheets/variables';


.root {
  display: flex;
  position:   fixed;
  z-index:    900;
  left:       0;
  top:        0;
  width:      100%;
  height:     100%;
  overflow-y: auto;
  align-items: center;
  background: rgba($congress-blue, .41);
}

.overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.viewport {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  align-items: center;
  justify-items: center;
  animation: dialog-scale-start .3s ease-in-out forwards;
}

.container {
  max-width: 100vw;
  max-height: 100vh;
}


@keyframes dialog-scale-start {
  0% {
    opacity: .5;
    transform: scale(1.15);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.measure {
  transition: height .3s ease-in-out;
  overflow: hidden;
  background-color: #ebedf4;
}

.sections {
  width: 100%;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}

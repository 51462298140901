@import 'stylesheets/variables';
@import 'stylesheets/mixins';

$focus-border: #2d95ff;
$read-only-color: #3e3e3e;
$code-color: #545454;

.form-control {
  font-family: 'Open Sans';
  width: 100%;
  height: 2.5625rem;
  box-sizing: border-box;
  padding: 0 0.9375rem;
  border: solid 0.0625rem $input-border;
  border-radius: 0.1875rem;
  outline: none;
  font-size: 0.875rem;
  background: transparent;
  appearance: none;
  resize: vertical;
  color: $greyish-brown-two;
  text-align: left;

  &[readonly] {
    @extend .readonly;
  }

  &:focus {
    border-color: $skyblue;
  }

  &::placeholder {
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    color: $placeholder;
  }

  // IE appearance prefix
  &::-ms-expand {
    display: none;
  }

  &::-ms-clear {
    display: none;
  }
}

.readonly {
  font-family: monospace;
  background-color: $disabled-background;
  color: $code-color;
  resize: none;
}

.select-placeholder {
  font-style: italic;
  color: $placeholder;
  line-height: initial;
}

.input-container {
  text-align: left;
  margin-bottom: 1.25rem;
}

.business-info-input-container {
  margin: 0;
}

.cookie-settings-container {
  margin: 0;
}

.change-password-container,
.create-password-container {
  margin-bottom: 0.9375rem;
}

.disabled {
  border-radius: 0.1875rem;
  background-color: $list-hover;
  border: solid 0.0625rem $input-border;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: 'Open Sans';
  text-align: left;
  color: $placeholder;
}

.prevent-auto-zoom-in-on-mobile {
  @include lt_sm {
    font-size: 1rem;
  }
}

@mixin inlineWidth($width, $max-width:'none', $next:'') {
  @extend .input-container;

  display: inline-block;
  width: $width;

  @if $max-width != 'none' {
    max-width: $max-width;

    @if ($next != '') {
      & + .#{$next} {
        margin-left: calc(100% - (#{$max-width} * 2));
      }
    }
  }
}

.inline-container {
  @include inlineWidth(50%);
}

.inline-container-half {
  @include inlineWidth(49%, 49%, 'inline-container-space');
}

.inline-container-space {
  @include inlineWidth(49%, 49%);
}

.inline-container-limited {
  @include inlineWidth(50%, 12.125rem, 'inline-container-limited');

  @include lt_sm {
    width: calc(50% - 1.25rem);

    & + .inline-container-limited {
      width: calc(50% - 1.25rem);
      margin-left: 2.5rem;
    }
  }

  .down-arrow {
    background: url(images/ico-down.svg) bottom 0.4375rem right 0.875rem no-repeat;
  }
}

.inline-container-cvc {
  @include inlineWidth(50%, 12.4375rem);
}

.inline-container-3 {
  @include inlineWidth(32%);

  margin-right: 2%;
}

.inline-container-3-last {
  @extend .inline-container-3;

  margin-right: 0;
}

.inline-container-6 {
  @include inlineWidth(66%);

  margin-right: 2%;

  & + .inline-container-3 {
    margin-right: 0;
  }
}

.inline-url-prefix {
  @include inlineWidth(17%);

  white-space: nowrap;
  vertical-align: bottom;
}

.inline-url-verify {
  @include inlineWidth(5.625rem);

  margin: 0;
  vertical-align: top;
}

.inline-url-input {
  @include inlineWidth(83%);
}

.inline-url-max {
  @include inlineWidth(100%);
}

.inline-family {
  @include inlineWidth(100%, 13.125rem);

  vertical-align: bottom;
  position: relative;

  .form-control {
    overflow: hidden;
    text-overflow: ellipsis;
    resize: none;
  }
}

.inline-480 {
  max-width: 30rem;
  vertical-align: bottom;
  position: relative;
  margin: 0 0 1.125rem 1.5625rem;

  .form-control {
    overflow: hidden;
    text-overflow: ellipsis;
    resize: none;
  }
}

.inline-family-max-width {
  @include inlineWidth(100%);

  vertical-align: middle;
  position: relative;
}

.inline-74 {
  @include inlineWidth(4.625rem);

  vertical-align: bottom;
}

.inline-70-margin-24 {
  @include inlineWidth(4.375rem);

  vertical-align: middle;
  margin-right: 1.5rem;
}

.inline-205-margin-31 {
  @include inlineWidth(12.8125rem);

  vertical-align: middle;
  margin: 0 1.9375rem 0 0;
}

.inline-61-margin-24 {
  @include inlineWidth(3.8125rem);

  vertical-align: middle;
  margin-right: 1.5rem;
}

.inline-205-margin-31 {
  @include inlineWidth(12.8125rem);

  vertical-align: middle;
  margin: 0 1.9375rem 0 0;
}

.inline-color {
  @include inlineWidth(8.8125rem);

  vertical-align: bottom;
}

.inline-color-with-label,
.modal-background,
.modal-content {
  @extend .inline-color;

  .gray-arrow {
    background: url(images/triangle-select.svg) bottom 1.0625rem right 0.625rem no-repeat;
  }
}

.inline-color-127 {
  @include inlineWidth(7.9375rem);

  vertical-align: bottom;
}

.inline-color-with-label {
  @include inlineWidth(8.75rem);

  .gray-arrow {
    background: $white url(images/triangle-select.svg) bottom 1.0625rem right 0.625rem no-repeat;
  }
}

.inline-color-with-label-space {
  @extend .inline-color-with-label;

  margin-left: 0.9375rem;
}

.inline-color-with-label-space-127 {
  @extend .inline-color-with-label;

  width: 7.9375rem;
}

.inline-color-with-label-space-127-margin-left {
  @extend .inline-color-with-label;

  width: 7.9375rem;
  margin-left: 0.9375rem;
}

.inline-color-with-label-space-150 {
  @extend .inline-color-with-label;

  width: 9.375rem;
}

.btn-background {
  @extend .inline-color-with-label;

  width: 7.9375rem;
}

.modal-background {
  @extend .inline-color-with-label;

  @include inlineWidth(7.9375rem);

  margin-left: 0.9375rem;
}

.inline-verify-update,
.inline-verify {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 5.625rem);

  .form-control {
    padding-right: 11.875rem;
  }
}

.inline-verify-update {
  .form-control {
    padding-right: 15rem;
  }
}

%label-input-container {
  display: block;
  color: $label-font;
  font-size: 0.875rem;
  font-weight: 500;
}

.label-input {
  @extend %label-input-container;

  &.input-validate {
    &::before {
      position: absolute;
      content: '';
      right: 0.9375rem;
      bottom: 0.6875rem;
      width: 1.125rem;
      height: 1.125rem;
      background-image: url(images/green-check.svg);
      background-size: contain;
    }
  }

  > span {
    @include setFont(Roboto, 0.8125rem, $charcoal-grey-four, 500);

    display: inline-block;
    margin-bottom: 0.4375rem;
  }
}

.label-input-grey {
  @extend .label-input;

  font-size: 0.75rem;

  > span {
    color: $warm-grey-six;
  }
}

.label-input-dark-blue-grey {
  @extend .label-input;

  font-size: 0.875rem;

  > span {
    color: $dark-blue-grey;
  }
}

.textarea-label-input {
  @extend .label-input;

  span {
    white-space: normal;
  }
}

.label-color-input {
  @extend %label-input-container;

  position: relative;

  .form-control {
    padding: 0.3125rem;
  }

  .color-circle {
    width: 1.6875rem;
    height: 1.6875rem;
    border-radius: 50%;
    background: $white;
    border: 0.0625rem solid $input-border;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.5rem 0 0;
  }
}

.input-description {
  @include setFont('Open Sans', 0.8125rem, $font-desc, 400);

  margin: 0 0 0.625rem;
  font-style: italic;
}

.title-description-nowrap {
  .input-title,
  .input-description {
    white-space: nowrap;
  }
}

.color-label {
  @include setFont(Roboto, 0.75rem, $warm-grey-six);

  display: block;
  margin-bottom: 0.4375rem;

  > span {
    white-space: nowrap;
  }
}

.label-arrow {
  background: url(images/blue-arrow.svg) center right 1.3125rem no-repeat;
}

.gray-arrow {
  background: $white url(images/triangle-select.svg) center right 0.625rem no-repeat;
  display: block;
  border-radius: 0.1875rem;
}

.blue-arrow {
  background: url(images/triangle-2.svg) center right 0.9375rem no-repeat;
  display: block;
}

.funnel-v2-dropdown {
  @include setFont(Roboto, 0.8125rem, $dusk-two, 500);

  text-align: left;
  margin:     0;
}

.funnel-v2-select-container-class {
  margin: 0;
}

.funnel-v2-select-classname {
  font-family: Roboto;
  font-size:   0.8125rem;
  font-weight: 500;
  padding:     0 0.8125rem;
}

.position {
  position: relative;
  display: inherit;
  width: 100%;
}

.code-snippet {
  @extend .readonly;

  height: 14.0625rem;
  padding: 0.9375rem;
}

.code-snippet-iframe {
  @extend .code-snippet;

  line-height: 1.3;
  padding: 0.6875rem 0.9375rem;
}

.read-only {
  font-family: monospace;
  background-color: $disabled-background;
  color: $read-only-color;
}

.no-radius-right {
  border-radius: 0.1875rem 0 0 0.1875rem;
  border-right-width: 0;

  &:focus {
    border-right-width: 0.0625rem;
  }
}

.no-radius-right-center {
  border-radius: 0.1875rem 0 0 0.1875rem;
  border-right-width: 0;
  text-align-last: center;

  &:focus {
    border-right-width: 0.0625rem;
  }
}

.no-radius-right-center-grey {
  border-radius: 0.1875rem 0 0 0.1875rem;
  border-right-width: 0;
  text-align-last: center;
  background-color: $pale-grey-twelve;

  &:focus {
    border-right-width: 0.0625rem;
  }
}

.no-radius-left {
  border-radius: 0 0.1875rem 0.1875rem 0;
}

.no-radius-left-width-127 {
  border-radius: 0 0.1875rem 0.1875rem 0;
  width: 7.9375rem;
}

.no-radius-left-width-517 {
  border-radius: 0 0.1875rem 0.1875rem 0;
  width: 32.3125rem;
}

.no-radius {
  border-radius: 0;
  border-right-width: 0;

  &:focus {
    border-right-width: 0.0625rem;
  }
}

.no-radius-margin-29 {
  border-radius: 0;
  border-right-width: 0;

  &:focus {
    border-right-width: 0.0625rem;
  }
}

.textarea {
  padding: 0.9375rem;
  height: auto;
}

.textarea-notify {
  padding: 0.9375rem;
  height: 8.5625rem;
  resize: none;
}

.hidden {
  display: none;
}

.error {
  border: 0.0625rem solid $red-danger;

  &:focus {
    border: 0.0625rem solid $red-danger;
  }
}

.dropdown {
  list-style-type: none;
  position: absolute;
  margin: 0;
  padding: 0;
  min-width: 100%;
  min-height: 6.25rem;
  height: 18.75rem;
  border: 0.0625rem solid $white-six;
  background: $white-three;
  box-shadow: 0 0.125rem 0.25rem 0 rgba($shadow-gray, .5);
  z-index: 9;
  overflow: auto;

  li {
    padding: 0.625rem 3.125rem 0.625rem 0.9375rem;
    white-space: nowrap;
    cursor: pointer;
    color: $warm-grey-two;
    font-size: 0.8125rem;
    position: relative;

    &:hover {
      background: $list-hover;
    }

    &.selected::after {
      @include sideElementBase(1.125rem, 0.75rem);

      background: url(images/checkmark.svg) no-repeat center;
      right: 0.625rem;
    }
  }
}

.hidden {
  display: none;
}

.cal-container {
  display: inline-block;
  width: 14.0625rem;
  position: relative;
  padding-top: 1.625rem;

  .cal-label {
    @include setFont(Roboto, 0.8125rem, $font-light-desc);

    line-height: 2.1;
    letter-spacing: 0.00625rem;
  }

  .form-control {
    border-radius: 0;
    width: 8.875rem;
    text-align: center;
    vertical-align: top;
  }

  .btn {
    @include setFont(Roboto, 1.125rem, $pinkish-grey-four);

    width: 2.5625rem;
    height: 2.5625rem;
    text-align: center;
    display: inline-block;
    border-radius: 0.1875rem 0 0 0.1875rem;
    border: solid 0.0625rem $input-border;
    border-right-width: 0;
    outline: none;
    cursor: pointer;
    background: $white;

    &:last-child {
      border-left-width: 0;
      border-right-width: 0.0625rem;
      border-radius: 0 0.1875rem 0.1875rem 0;
    }
  }

  .free {
    @include setFont(Roboto, 0.6875rem, $font-light-gray);

    position: absolute;
    bottom: 0.875rem;
    right: 4.375rem;
    letter-spacing: 0.00625rem;
  }
}

.textarea-notify-form-container {
  max-width: 30rem;
  height: 8.5625rem;
  margin: 0 0 1.125rem 1.5625rem;
}

.notify-user-dropdown {
  @extend .input-container;
  @extend .inline-480;

  .dropdown {
    @extend .dropdown;

    width: 100%;
    height: auto;
    box-sizing: border-box;

    @include lt_lg {
      min-width: 43.4375rem;
    }

    @include lt_md {
      min-width: 100%;
    }

    @include lt_sm {
      min-width: 100%;
    }

    li {
      white-space: normal;
      line-height: 1.25;

      &.selected::after {
        top: 50%;
        transform: translateY(-50%);
      }

      @include lt_sm {
        padding: 0.625rem 1.875rem 0.625rem 0.9375rem;
      }
    }
  }
}

.textarea-how-did-you-find-us {
  width: 16.5625rem;
  height: 4.4375rem;
  border-radius: 0.1875rem;
  background-color: $white;
  border: solid 0.0625rem $iron;
  resize: none;
  margin-left: 1.5rem;
  padding: 0.6875rem 1.875rem 0.6875rem 0.9375rem;
}

.background-white {
  background: $white;
}

.category-select {
  border-radius: 0.25rem;
  border: solid 0.0625rem $silver-two;
  background: url(images/orange-notice-mark.svg) center left 0.5625rem no-repeat, linear-gradient(to top, $pale-grey, $ice-blue);
  margin-top: 0.375rem;

  select {
    @include setFont(Roboto, 0.875rem, $abbey, 500);

    font-style: normal;
    letter-spacing: 0.00625rem;
    padding-left: 1.9375rem;
    min-width: 16.5625rem;
  }
}

.cookie-settings-select {
  font-size: 0.875rem;
  color: $dark-blue;
  font-weight: 500;
  font-style: normal;
  min-width: 14.1875rem;
}

.cookie-settings-select-forbidden {
  opacity: .4;
}

.cookie-settings-select-disabled {
  opacity: .4;

  &:hover {
    cursor: not-allowed;
  }
}

.white-background-with-down-arrow {
  background: $white url(images/triangle-2.svg) center right 0.9375rem no-repeat;
}

.root {
  padding: var(--website-menu-list-option-padding);

  &:not(:last-child) {
    border-bottom: 1px solid #ced4da;
  }
}

.highlight {
  background-color: #4672ff33;
}

.focused {
  background-color: #f5f6fa;
}

.name {
  color: #6c7794;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}

.url {
  color: #363840;
  font-size: 0.875rem;
}

@import 'stylesheets/variables';


.loadingMessage {
  color: #ccc;
}

.errorMessage {
  color: $failed-red;
}

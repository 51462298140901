.root {
  list-style: none;
  margin: 1rem 0 1rem 0;
  padding: 0;
  font-size: 0.75rem;

  & > * {
    &:not(:last-child) {
      margin-bottom: 0.875rem;
    }
  }
}

.title {
  font-weight: 500;
  color: #99a0a7;
  margin: 0;
  text-transform: uppercase;
}

.linkGroup {
  list-style: none;
  margin: 0;
  padding: 0;
  & > *:not(:last-child) {
    margin-bottom: 0.875rem;
  }
}

.link {
  text-decoration: none;
  color: var(--link-color);
  font-size: 0.875rem;
  margin-left: 1rem;
  
  &:hover {
    color: var(--link-hover-color);
  }
}

.root {
  padding: var(--website-menu-list-option-padding);
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.button {
  width: 100%;
}

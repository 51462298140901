.root {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: var(--icon-url);
}

.custom {
  background-size: cover;
}

.generic {
  background-size: 70%;
}

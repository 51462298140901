@mixin fadeDisplay($display, $opacity) {
  display: $display;
  opacity: $opacity;
}

@keyframes fadeIn {
  0% {
    @include fadeDisplay(none, 0);
  }

  1% {
    @include fadeDisplay(block, 0);
  }

  100% {
    @include fadeDisplay(block, .98);
  }
}

@keyframes fadeInSlow {
  0% {
    @include fadeDisplay(none, 0);
  }

  50% {
    @include fadeDisplay(block, 0);
  }

  100% {
    @include fadeDisplay(block, .98);
  }
}

@keyframes fadeInUp {
  0% {
    @include fadeDisplay(none, 0);
  }

  1% {
    @include fadeDisplay(block, 0);

    margin-top: 3.75rem;
  }

  100% {
    @include fadeDisplay(block, .98);

    margin-top: 0;
  }
}

@keyframes bannerFadeIn {
  0% {
    @include fadeDisplay(none, 0);
  }

  1% {
    @include fadeDisplay(block, 0);

    bottom: -7.5rem;
  }

  100% {
    @include fadeDisplay(block, .98);

    bottom: 0;
  }
}

@keyframes cardFadeInFirst {
  0% {
    opacity: 0;
    top: 18.75rem;
  }

  50% {
    opacity: 1;
  }

  100% {
    top: 0;
  }
}

@keyframes cardFadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
    margin-top: 8rem;
  }

  100% {
    top: 0;
    opacity: 1;
    margin-top: 0;
  }
}

.fade-in {
  animation: fadeIn .5s;
}

.fade-in-slow {
  animation: fadeInSlow 1s;
  outline: none;
}

.fade-in-up {
  animation: fadeInUp .8s;
}

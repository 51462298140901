@import 'stylesheets/variables';

.root {
  width: 100%;
  height: 3.1875rem;
  background-color: #ed6d6d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alertMessage {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0 1.25rem 0 0.4375rem;
}

.alertImage {
  width: 0.9375rem;
  height: 0.75rem;
}

.button {
  font-size: 0.8125rem;
  color: #fff !important;
  border: 0.0625rem solid #fff !important;
  background: transparent !important;

  &:hover {
    color: #ed6d6d !important;
    background-color: #fff !important;
    cursor: pointer;
  }
}


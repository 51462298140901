$icon-width: 1.875rem;
$icon-height: $icon-width;

.root {
  position: relative;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 0;
  padding: 0;
  appearance:none;
  outline: none;
  background: transparent;
}

.body {
  display: flex;
  align-items: center;
}

.root {
  position: sticky;
  top: 5.625rem;
  font-size: 0.875rem;
}

.disabled {
  opacity: 0.32;
  pointer-events: none;
}

@import 'stylesheets/variables';
@import 'stylesheets/mixins';

.missing-content-block,
.migrate-page {
  max-width: 31.875rem;
  width: 100%;
  margin: 0 auto;
  padding: 6.625rem 0;
  text-align: center;
  font-family: Roboto;

  .no-invoice {
    @include missingContentLogo("images/no-invoice.svg");
  }

  .no-page {
    @include missingContentLogo("images/no-page.svg");
  }

  .no-doc {
    @include missingContentLogo("images/no-page.svg");
  }

  .doc-no-exist {
    @include missingContentLogo("images/create-doc.svg");
  }

  .migrate-icon {
    @include missingContentLogo("images/migrate-icon.svg");
  }

  .title {
    font-size: 1.3125rem;
    color: $dark-gray;
    margin: 2.625rem 0 0;
    font-weight: 400;
  }

  .caption {
    font-size: 0.9375rem;
    letter-spacing: 0.0125rem;
    color: $font-light-gray;
    margin: 1.25rem 0 1.875rem;
  }
}

.migrate-page {
  max-width: 36.25rem;
  font-family: Roboto;
  text-align: left;

  .title {
    font-size: 1.6875rem;
    margin: 0.625rem 0 0;
  }

  .caption {
    font-family: Roboto;
    font-size: 0.875rem;
    line-height: 1.5;
  }
}

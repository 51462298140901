@import "../../../../variables";


.root {
  display: flex;
  align-items: center;
  height: 2.25rem;
  margin-left: (2.375rem + $outer-vertical-margin);
  color: #99a0a7;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

$pale-grey-nine: #f5f6fa;
$jeans-blue: #013986;
$steel-two: #6c7794;


.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: stretch;
  background-color: $pale-grey-nine;
  font-family: Roboto;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 140px;
}

.logo {
  height: 35px;
  width: 35px;
}

.title {
  color: $jeans-blue;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.abstractArt {
  height: 160px;
  width: 160px;
  margin-bottom: 28px;
}

.bodyText {
  color: $steel-two;
  max-width: 20em;
  max-width: 17em;
  text-align: center;
  line-height: 1.375 
}

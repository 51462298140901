$active-indicator-width: 0.125rem;
$active-indicator-border-left: $active-indicator-width solid #4672ff;

.root {
  border-left-style: solid;
  border-left-width: $active-indicator-width;
  cursor: pointer;
  height: 2.75rem;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:not(.disabled) {
    &:hover {
      background-color: #fff;
    }
  }

  &:not(.active) {
    border-left-color: transparent;
  }
}

.active {
  background-color: #fff;
  border-left-color: #4672ff;
}

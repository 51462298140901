.root {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0.1875rem;
  box-shadow: 0 0 0.5rem rgba(#000, .3);
  background-color: #fff;
  padding: 2.5rem 3.125rem;
}

.closeButton {
  width: 0.875rem;
  height: 0.875rem;
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  outline: none;
  appearance: none;
  cursor: pointer;
  padding: 0;
  border: 0;
  margin: 0;
  background-color: transparent;
}

.noClose {
  display: none;
}

// Normally, I don't like to nest rules, but the alternative is either
// to use `!important` or to make sure that the consumers of this
// component have their Emotion cache set to prepend <style>s to the <head>
// rather than append them--neither of which give me the warm-and-fuzzies.
// See https://github.com/JedWatson/react-select/issues/4953#issuecomment-991059178
//
.root {
  .dropdownIndicator {
    transition: transform .2s ease !important;

    &.isOpen {
      transform: rotate(180deg);
    }
  }

  .option {
    color: #000;

    &.isDisabled {
      opacity: 0.4;
    }

    &.isFocused,
    &.isSelected
    {
      background-color: #f5f6fa;
    }

    &:active:not(.isDisabled) {
      background-color: #b2d4ff;
    }
  }
}

@import 'stylesheets/variables';


.root {
  background-color: #fff;
  max-width: var(--content-max-width, #{$content-max-width});
  min-width: var(--content-min-width, #{$content-min-width});
  width: 100%;
  height: 100%;
}

@import 'stylesheets/variables';
@import 'stylesheets/animations';
@import 'stylesheets/mixins';

.popover {
  @include absoluteBasicBottom(3.125rem, 0, $white-three);

  display: inline-block;
  border: solid 0.0625rem $pale-grey-three;

  &::before {
    @include borderBasicBottom(-0.6875rem, 0.6875rem, $light-gray);

    top: -0.6875rem;
    right: 1.875rem;
  }

  &::after {
    @include borderBasicBottom(-0.5625rem, 0.625rem, $white-three);

    right: 1.9375rem;
  }
}

.popover-point {
  @extend .popover;

  top: 2.625rem;
  right: 0;
}

.popover-point-narrow {
  @extend .popover;

  right: -1.375rem;
}

.popover-danger {
  @include absoluteBasicBottom(null, 0, $error-msg);

  color: $white;
  padding: 0.625rem 1.25rem;
  border-radius: 0 0 0.1875rem 0.1875rem;
  font-size: 0.75rem;
}

.popover-tooltip {
  @include absoluteBasicTop(3.5rem, 0, $pale-grey-four);

  width: 20.1875rem;
  animation: fadeInSlow 3s;
  display: inline-block;
  border: solid 0.0625rem $white-six;
  border-radius: 0.1875rem;
  padding: 1.1875rem 1.875rem 1.1875rem 0.9375rem;

  &::before {
    @include borderBasicTop(-0.6875rem, 0.6875rem, $white-six);

    left: 3.875rem;
  }

  &::after {
    @include borderBasicTop(-0.625rem, 0.625rem, $pale-grey-four);

    left: 3.9375rem;
  }
}

%verify-popover-basic {
  z-index: 1;
  display: inline-block;
  border: solid 0.0625rem $pale-grey-three;
  width: 20.625rem;
  padding: 1.25rem;
  box-sizing: border-box;
}

.popover-top {
  @include absoluteBasicTop(2.5rem, -1.625rem, $pale-grey-four);

  @extend %verify-popover-basic;

  &::before {
    @include borderBasicTop(-0.6875rem, 0.6875rem, $white-six);

    left: 1.6875rem;
  }

  &::after {
    @include borderBasicTop(-0.625rem, 0.625rem, $pale-grey-four);

    left: 1.75rem;
  }
}

.popover-bottom {
  @include absoluteBasicBottom(2.5rem, -1.625rem, $pale-grey-four);

  @extend %verify-popover-basic;

  &::before {
    @include borderBasicBottom(-0.6875rem, 0.6875rem, $white-six);

    right: 1.6875rem;
  }

  &::after {
    @include borderBasicBottom(-0.625rem, 0.625rem, $pale-grey-four);

    right: 1.75rem;
  }
}

.popover-top-mark {
  @extend .popover-top;

  bottom: 1.875rem;
  white-space: initial;
}

.popover-top-for-checkout {
  @extend .popover-top;

  width: 26.25rem;
  bottom: 1.875rem;
}

.popover-top-progress {
  @extend .popover-top;

  width: auto;
  padding: 0.625rem 0.9375rem;
  border-radius: 0.1875rem;
  bottom: 1.5625rem;
  left: calc(50% - 2.0625rem);
}

.popover-pricing-info {
  @extend .popover-top;

  bottom: 2rem;
  padding: 1.25rem 1.0625rem 1.625rem;
  width: 13.125rem;
  color: $white;
  background-color: $cerulean;
  border: solid 0.0625rem $cerulean;
  line-height: 1.46;
  letter-spacing: 0.00625rem;
  font-size: 0.8125rem;
  text-align: left;

  &::after {
    border-top-color: $cerulean;
  }
}

.popover-row-info {
  @extend .popover-top;

  bottom: 2rem;
  padding: 1.25rem 1.0625rem 1.625rem;
  width: 13.125rem;
  color: $white;
  background-color: $picton-blue;
  border: solid 0.0625rem $picton-blue;
  line-height: 1.46;
  letter-spacing: 0.00625rem;
  font-size: 0.8125rem;
  text-align: left;

  &::after {
    border-top-color: $picton-blue;
  }
}

.popover-col-info {
  @extend .popover-top;

  bottom: 2rem;
  padding: 1.25rem 1.0625rem 1.625rem;
  width: 13.125rem;
  color: $polo-blue;
  background-color: $zircon;
  border: solid 0.0625rem $link-water;
  line-height: 1.46;
  letter-spacing: 0.00625rem;
  font-size: 0.8125rem;
  text-align: left;

  &::after {
    border-top-color: $zircon;
  }
}

.popover-bottom-info {
  @extend .popover-bottom;

  border-radius: 0.1875rem;
  width: 42.1875rem;
  color: $white;
  background-color: $azure-seven;
  line-height: 1.54;
  font-size: 0.8125rem;

  &::after {
    border-bottom-color: $azure-seven;
  }
}

.popover-col-info-testing-version {
  @extend .popover-top;

  @include setFont(Roboto, 0.75rem, $pricing-testing-plan-title, normal);

  bottom: 2rem;
  padding: 0.625rem 1.25rem 0.9375rem;
  width: 13.75rem;
  line-height: normal;
  letter-spacing: 0.00625rem;
  text-align: left;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, .03), 0 0 1.25rem 0 rgba(58, 69, 100, .07);
  border: solid 0.0625rem $pricing-testing-tool-tip-border;
  background-color: $zircon;

  &::after {
    border-top-color: $zircon;
  }
}

.popover-top-preview-failed {
  @include absoluteBasicTop(3.4375rem, -1.625rem, $pale-grey-four);

  @extend %verify-popover-basic;

  text-align: left;
  width: 15.625rem;

  &::before {
    @include borderBasicTop(-0.6875rem, 0.6875rem, $white-six);

    left: 3.4375rem;
  }

  &::after {
    @include borderBasicTop(-0.625rem, 0.625rem, $pale-grey-four);

    left: 3.5rem;
  }
}

@import 'stylesheets/variables';
@import 'stylesheets/mixins';

.landing-page {
  text-align: center;
  font-family: Roboto;
  padding-bottom: 0.5rem;

  .text {
    margin: 0;
  }
}

.capitalize {
  text-transform: capitalize;
}

.title,
.title-center {
  @include setFont(Roboto, 1.125rem, $dark-gray, normal);

  margin: 0 0 2.8125rem;
}

.title-center {
  text-align: center;
}

.title-outer {
  @include setFont(Roboto, 1.375rem, $dark, normal);

  letter-spacing: 0.0125rem;
  margin: 1.4375rem auto 1rem;
}

.description {
  font-size: 0.9375rem;
  color: $font-light-gray;
  line-height: 1.73;
  max-width: 30rem;
  margin: 1.25rem auto;
  padding: 0 0.625rem;
}

.title-outer + .description {
  margin-top: 1rem;
}

.form-container-description {
  margin-top: 1.875rem;
  color: $label-font;
  font-size: 0.875rem;
}

.description-star {
  @extend .description;

  @include setFont(Roboto, 0.6875rem, $greyish-three, 400);

  text-align: left;
  margin: 0.625rem 0 0;

  &::before {
    content: '*';
    color: $skyblue;
    margin-right: 0.3125rem;
  }
}

.form-container {
  width: 100%;
  max-width: 31.25rem;
  padding: 2.1875rem 2.1875rem 0;
  background: $white;
  margin: 1.875rem auto;
  box-shadow: 0 0.125rem 0.25rem 0 rgba($black, .2);
  box-sizing: border-box;
  text-align: left;
  border: 0.0625rem solid $dashed-color;
}

.question-form-container {
  @extend .form-container;

  padding: 2.9375rem 2.9375rem 0;
  margin: 2.8125rem auto 1.25rem;
}

.success-container {
  padding: 2.75rem 2.9375rem;
  margin: 2.8125rem auto 1.25rem;
}

.redirect-msg {
  margin: 1.25rem 0 1.875rem;
  font-size: 0.875rem;
  color: $label-font;
}

.link-style {
  color: $dark-sky-blue-two;
  text-decoration: underline;
  cursor: pointer;
}

.redirect-link {
  display: block;
  text-align: right;
  text-decoration: none;
  color: $label-font;
  margin-bottom: 1.25rem;
  transition: .5s;
  font-size: 0.75rem;

  &:hover {
    color: $dark-sky-blue-two;
  }
}

.social-modal {
  width: 100%;
  max-width: 31.25rem;
  margin: 1.25rem auto 0;
}

.user-icon {
  width: 3.625rem;
  height: 3.625rem;
  margin: 0.5625rem auto;
  background-image: url(images/user-icon.svg);
}

.user-instructions {
  margin: 1.875rem auto;
  font-size: 0.75rem;
  line-height: 1.5;
  letter-spacing: 0.00625rem;
  text-align: center;
  width: 25.375rem;
  color: $font-light-desc;

  span {
    color: $dark;
    font-weight: 500;
  }
}

.migrate-account,
.create-new-account {
  margin-top: 1.4375rem;
  font-size: 1.375rem;
  font-family: Roboto;
  letter-spacing: 0.0125rem;
  color: $dark;
}

.migrate-account-header {
  margin: 1.875rem auto 0;
  width: 31.25rem;
  font-family: Roboto;
  font-size: 0.875rem;
  line-height: 1.86;
  letter-spacing: 0.0125rem;
  color: $font-light-gray;
  font-weight: 300;

  .email {
    color: $dark;
    font-weight: 500;
  }
}

.modal-container {
  margin-top: -3.4375rem;

  .title {
    @include setFont(Roboto, 1rem, $dialog-caption, 600);

    margin: 1.625rem 0 1.4375rem;
  }

  .form-container {
    padding: 0;
    border: none;
    box-shadow: none;
  }

  .redirect-msg {
    margin-top: -1.5625rem;
  }

  .legal-terms {
    float: none;
    width: auto;
    margin-bottom: 1.875rem;
  }
}

@import 'stylesheets/mixins';
@import 'stylesheets/variables';

@import './variables';


.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  // If align-items is 'center' below the minimum page width, then the
  // content will push off the LH side of the window and it won't be
  // reachable even with the horizontal scrollbar [TER-8992].
  @media only screen and (min-width: $content-min-width) {
    align-items: center;
  }
}

.hatAndBanners {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
}

.banner {
  height: 3.1875rem;
  transition: all 500ms cubic-bezier(.215, .61, .355, 1);
  width: 100%;
}

.header {
  background: $white;
  filter: drop-shadow(0rem 0.25rem 0.625rem rgba(0, 50, 123, 0.1));
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;

  & > * {
    padding: 0 $outer-vertical-margin;
  }

  // See TER-8992 comment, above
  @media only screen and (min-width: $content-min-width) {
    justify-content: center;
  }
}

.main {
  display: flex;
  flex: 1 0 100%;
  border-left: 0.0625rem solid #ebedf4;
  border-right: 0.0625rem solid #ebedf4;
  box-shadow: 0rem 0rem 1.875rem 0rem rgba(0, 50, 123, 0.05);
}

.mainContent {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.sidebar {
  width: $sidebar-width;
  min-width: $sidebar-width;
  background-color: $pale-grey-nine;
  padding-top: 2.1875rem;
}

.body {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}

@import "~stylesheets/mixins.scss";
@import "~stylesheets/variables.scss";

@import "../../../../variables";

$active-indicator-width: 0.125rem;
$active-indicator-border-left: $active-indicator-width solid #4672ff;

$base-padding-left: 3.4375rem + $outer-vertical-margin;

.root {
  margin-bottom: 0.125rem;
}

.link {
  padding-left: $base-padding-left;
  display: flex;
  align-items: center;
  color: #63656D;
  font-weight: 400;
  text-decoration: none;
  width: 100%;
  height: 100%;
}

.root {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-bottom: 0.5rem;

  &:hover {
    .name {
      color: #4672ff;
    }
  }
}

.name {
  color: #363840;
  font-weight: 500;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.4286;
}

.email {
  color: #6c7794;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.8333;
}

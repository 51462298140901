@import "~stylesheets/mixins.scss";
@import "~stylesheets/variables.scss";

@import "../../variables";


.root {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  cursor: pointer;
  height: 2.75rem;
  font-weight: 500;
  color: $charcoal-grey-four;
}

.inactive {
  opacity: .32;
  cursor: default;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
  background-size: 1.25rem 1.25rem;
  margin-left: 1.875rem;
}

.carat {
  width: 1.5rem;
  height: 1.5rem;
  background-image: url(./img/carat-down.svg);
  background-size: 1.5rem 1.5rem;
  margin-right: $outer-vertical-margin;
}

.carat--open {
  background-image: url(./img/carat-up.svg);
}

.text {
  flex: 1;
}



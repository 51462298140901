.logout {
  color: #363840;
  line-height: 1.833;
  margin-top: 0.5rem;
  display: block;

  &:hover {
    color: #4672ff;
  }
}

.bold {
  font-weight: 500;
}

.link {
  color: #4672ff;
  text-decoration: none;
}

.popOutIcon {
  vertical-align: middle;
  height: 0.9375em;
  margin-left: 0.25em;
  margin-right: 0.25em;
}

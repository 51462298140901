@import 'stylesheets/variables';
@import 'stylesheets/popover-list';


.logo-header {
  text-align: center;
  display: block;
  padding: 6.25rem 0 1.5625rem;

  .logo {
    background: url("images/logo.svg") no-repeat center;
    background-size: cover;
    width: 6.625rem;
    height: 2.375rem;
    display: inline-block;
    vertical-align: middle;
  }
}

.root {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  height: 3.75rem;
  column-gap: 1.5625rem;
}

.leftContent {
  // TODO: these values will be carried over once we get the parent layout component
  // (the overall page layout) genericized and brought over into @termly_web/common
  // width: $sidebar-width - $outer-vertical-margin + $sidebar-margin-right;
  // We'll likely be using CSS variables.
  // For now, this value suffices for the builder header.
  width: 15.125rem;
  display: flex;
}

.logo {
  height: 1.875rem;
  width: auto;
  margin-right: 1rem;
}

.middleContent {
  flex: 1;
  padding-left: 2.5625rem;
}

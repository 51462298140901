.root {
  alignItems: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.content {
  color: #4f5c6e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  max-height: 3.75rem;
  max-width: 3.75rem;
  aspect-ratio: 1;
}

.title {
  color: #212529;
  font-size: 1.25rem;
  font-weight: 500;
}

.instructions {
  color: #6d7782;
  font-size: 0.875rem;
  margin: 0;
}

// the breakpoints are aligned with the grid system 'flexbox-grid' we are using.
$breakpoints: (
  sm: 36rem,
  md: 48rem,
  lg: 62rem,
  xl: 75rem,
) !default;

@mixin responsive($size, $attr: 'min-width') {
  $value: map-get($breakpoints, $size);

  $breakpoint: if(($attr == 'max-width'), $value, $value + 0.0625rem);

  @media only screen and ($attr: $breakpoint) {
    @content;
  }
}

@mixin sm {
  @include responsive(sm) { @content; }
}

@mixin lt_sm {
  @include responsive(sm, 'max-width') { @content; }
}

@mixin md {
  @include responsive(md) { @content; }
}


@mixin lt_md {
  @include responsive(md, 'max-width') { @content; }
}

@mixin lg {
  @include responsive(lg) { @content; }
}

@mixin lt_lg {
  @include responsive(lg, 'max-width') { @content; }
}

@mixin xl {
  @include responsive(xl) { @content; }
}

@mixin lt_xl {
  @include responsive(xl, 'max-width') { @content; }
}

@mixin absolute-center($type: null) {
  position: absolute;
  margin: auto;

  @if $type == 'horizontal' {
    left: 0;
    right: 0;
  }

  @else if $type == 'vertical' {
    top: 0;
    bottom: 0;
  }

  @else {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@mixin setFont($font-family, $font-size, $color, $font-weight: 500) {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $color;
}

@mixin fontStyle($font-family, $font-size, $font-style, $font-weight, $color) {
  @include setFont($font-family, $font-size, $color, $font-weight);

  font-style: $font-style;
}

@mixin FontDimensionStyle($font-family, $font-size, $color, $text-align: center) {
  font-family: $font-family;
  font-size: $font-size;
  color: $color;
  text-align: $text-align;
}

@mixin missingContentLogo($url) {
  background: url($url) no-repeat center;
  width: 100%;
  height: 5.3125rem;
  display: inline-block;
}

@mixin maskChild($image, $width, $height, $margin-left, $margin-top) {
  background: url($image) no-repeat center;
  background-size: cover;
  width: $width;
  height: $height;
  display: inline-block;
  vertical-align: middle;
  margin: $margin-top 0 0 $margin-left;
}

@mixin pictureBlockTheme($padding) {
  display: inline-block;
  width: 1.875rem;
  height: 1.5rem;
  background: url(images/picture-icon.svg) no-repeat center;
  border: 0.0625rem dashed $input-border;
  padding: $padding;
}

@mixin sideElementBase($width, $height, $position: absolute) {
  width: $width;
  height: $height;
  display: inline-block;
  content: '';
  position: $position;
  box-sizing: border-box;
}

@mixin socialLoginButton($icon, $icon-hover) {
  cursor: pointer;
  border-radius: 0.3125rem;
  height: 2.5625rem;
  width: 15rem;
  color: $social-btn;
  border: solid 0.0625rem $social-btn;
  background: transparent;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.6875rem;
  font-family: 'Open Sans';
  font-weight: 400;
  letter-spacing: 0.03125rem;
  outline: 0;
  transition: background .5s;

  span {
    display: inline-block;
    line-height: 1.1875rem;
    vertical-align: middle;
  }

  &::before {
    @include sideElementBase(1.0625rem, 1.125rem, inherit);

    background: url('images/#{$icon}') no-repeat center;
    vertical-align: middle;
    margin-right: 0.625rem;
  }

  &:hover {
    color: $white;
    background: linear-gradient(to right, $skyblue, $cornflower-blue);
    border-color: transparent;

    &::before {
      background: url('images/#{$icon-hover}') no-repeat center;
    }
  }
}

@mixin setIcon($width, $height, $url) {
  width: $width;
  height: $height;
  background: url($url) no-repeat center;
  margin: 0 auto 2.6875rem;
}

@mixin borderBasic($width) {
  position: absolute;
  display: block;
  border-color: transparent;
  border-style: solid;
  box-sizing: border-box;
  content: "";
  border-width: $width;
}

@mixin borderBasicBottom($top, $width, $color) {
  @include borderBasic($width);

  top: $top;
  border-top-width: 0;
  border-bottom-color: $color;
}

@mixin borderBasicTop($bottom, $width, $color) {
  @include borderBasic($width);

  bottom: $bottom;
  border-bottom-width: 0;
  border-top-color: $color;
}

@mixin absoluteBasic($color) {
  position: absolute;
  background-color: $color;
  box-shadow: 0 0.125rem 0.25rem 0 rgba($shadow-gray, .5);
  z-index: 2;
}

@mixin absoluteBasicBottom($top, $right, $color) {
  @include absoluteBasic($color);

  top: $top;
  right: $right;
}

@mixin absoluteBasicTop($bottom, $left, $color) {
  @include absoluteBasic($color);

  bottom: $bottom;
  left: $left;
}

@mixin overFlowDot() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin button-theme($bg-color, $text-color, $border-color, $hover-color: '') {
  background: $bg-color;
  color: $text-color;
  border: 0.0625rem solid $border-color;

  @if $hover-color {
    transition: background .5s;

    &:hover {
      background: $hover-color;
      color: $white;
    }
  }
}

@mixin icon-basic($image) {
  width: 1.1875rem;
  height: 1.1875rem;
  display: inline-block;
  vertical-align: bottom;
  margin-right: 0.75rem;
  background: url($image) no-repeat center;
}

@mixin set-width($xl-width, $lg-width, $md-width, $sm-width) {
  width: $xl-width;

  @include lg {
    width: $lg-width;
  }

  @include md {
    width: $md-width;
  }

  @include sm {
    width: $sm-width;
  }
}

@mixin alignChildVerticallyCenter() {
  display: flex;
  align-items: center;
}

@mixin shine-line($width) {
  animation: shine-lines 1.6s infinite linear;

  @keyframes shine-lines {
    0% {
      background-position: -6.25rem;
    }

    100% {
      background-position: $width;
    }
  }
}

@mixin bounceInFromTop($start) {
  animation: bounce .3s 1 ease-in-out;

  @keyframes bounce {
    0% {
      top: $start;
    }

    70% {
      top: 0;
    }

    80% {
      top: -0.625rem;
    }

    100% {
      top: 0;
    }
  }
}

@mixin delayBounceInFromTop($delay, $start) {
  animation: bounce $delay 1 ease-in-out;

  @keyframes bounce {
    0% {
      top: $start;
    }

    50% {
      top: $start;
    }

    85% {
      top: 0;
    }

    90% {
      top: -0.625rem;
    }

    100% {
      top: 0;
    }
  }
}

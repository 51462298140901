.root {
  align-items: center;
  background: #f6f3ff;
  border-radius: 5px;
  color: #5546ff;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  gap: 0.5rem;
  line-height: 1.5rem;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
}

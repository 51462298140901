.root {
  border-bottom: 1px solid #ced4da;
  display: flex;
  justify-content: center;
  padding: var(--website-menu-list-option-padding);
}

.button {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  font-weight: 500;
  gap: 0.5rem;
}

.icon {
  height: 1em;
  transform: scaleX(-1);
  width: auto;

  [dir="rtl"] & {
    transform: none;
  }
}

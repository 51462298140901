@import 'stylesheets/variables';
@import 'stylesheets/mixins';
@import 'stylesheets/landing-page.scss';

.default {
  &:empty {
    display: none;
  }
}

.thank-you-title {
  @extend .title-outer;

  letter-spacing: 0.0125rem;
  margin-bottom: 1.875rem;
}

.thank-you-description {
  @extend .description;

  max-width: 31.25rem;
  letter-spacing: 0.0125rem;
  margin-bottom: 2.5rem;
}

.question-form-title {
  @include setFont(Roboto, 1.0625rem, $dark, 400);

  margin: 0 auto;
  line-height: 1.53;
  text-align: center;
}

.billing-title {
  @include setFont(Roboto, 1.125rem, $dark, 400);

  margin: 2.1875rem 0 0.9375rem;
  line-height: 1.9375rem;
  letter-spacing: 0.0125rem;
}

.checkout-description {
  font-size: 0.9375rem;
  color: $font-light-gray;
  line-height: 1.73;
  max-width: 48.75rem;
  margin: 1rem auto 1.25rem;
  padding: 0 0.625rem;
}

.delete-account-page-title {
  @include setFont(Roboto, 1.6875rem, $dark, 400);

  line-height: normal;
  margin: 0.3125rem 0 1.25rem;
}

.delete-account-page-description {
  @include setFont(Roboto, 0.75rem, $font-light-desc, 400);

  line-height: 1.5;
  margin: 0 0 2.5rem;
}

.dialog-confirm-title {
  @include setFont(Roboto, 1.125rem, $dark);

  text-align: center;
  letter-spacing: 0.0125rem;
  margin-bottom: 0.9375rem;
}

.dialog-confirm-description {
  @include setFont(Roboto, 0.75rem, $font-light-desc);

  margin: 0 0 1.875rem;
  text-align: center;
  letter-spacing: 0.0125rem;
  line-height: 1.4;
}

.dialog-discard-title {
  @include setFont(Roboto, 1.125rem, $dark, 500);

  letter-spacing: 0.0125rem;
  margin: 2.6875rem 0 1rem;
}

.dialog-create-password {
  font-size: 0.75rem;
  -webkit-font-smoothing: antialiased;
  color: $label-font;
  margin: -0.4375rem 0 1.875rem;
  line-height: 1.5;
}

.question-title {
  @include setFont(Roboto, 0.9375rem, $dark);

  margin: 0 0 0.75rem;
  line-height: 1.3;
}

.question-description {
  @include setFont(Roboto, 0.75rem, $font-desc, 400);

  margin: 0;
  line-height: 1.6;
}

.expired-title {
  color: $dark-gray;
  font-size: 1rem;
  margin: 0 0 0.625rem;
}

.label-title {
  font-size: 0.875rem;
  display: block;
  padding: 0.9375rem 0 0.625rem;
  color: $label-font;
  font-weight: 500;
}

.upgrade-mask-description {
  @include setFont(Roboto, 0.75rem, $font-light-desc);

  line-height: 1.5;
  letter-spacing: 0.00625rem;
  margin: 0 0 1.0625rem;
}

.image-uploader-title {
  @include setFont(Roboto, 0.875rem, $button-gray, 500);

  font-style: inherit;
  margin: 0;
  padding-top:0.875rem;
}

.image-uploader-caption {
  @include fontStyle('Open Sans', 0.8125rem, italic, inherit, $font-desc);

  line-height: 3.15;
  margin: 0 0 0.25rem;
}

.notification-title {
  @include setFont(Roboto, 1.125rem, $dark, normal);

  margin: 0 0 1.875rem;
}

.notify-user-title {
  @include setFont(Roboto, 1.6875rem, $dark, normal);

  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.01875rem;
  text-align: center;
  margin: 3.0625rem 0 1.1875rem;
}

.notify-user-description {
  @include setFont(Roboto, 0.875rem, $button-gray);

  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 1.9375rem;
  border-bottom: 0.0625rem dashed $light-gray;
}

.notify-user-warn {
  @include setFont('Open Sans', 0.75rem, $placeholder, 600);

  font-style: italic;
  text-align: left;
  margin-bottom: 5.4375rem;
  max-width: 31.25rem;
}

.plan-title {
  @include setFont(Roboto, 1.125rem, $font-light-gray, 600);

  line-height: 1.4;
  letter-spacing: 0.0125rem;
  text-align: left;
  margin: 0;
  padding-bottom: 1.1875rem;
  border-bottom: 0.0625rem dashed $input-border;
}

.product-title {
  @include setFont(Roboto, 2.5rem, $white, 500);

  margin: 0 0 0.5625rem;
  padding: 0 0.625rem;
  letter-spacing: 0.0125rem;

  @include lt_md {
    font-size: 2.1875rem;
    max-width: 35.625rem;
    margin: 0 auto 0.75rem;
  }

  @include lt_sm {
    font-size: 2.1875rem;
  }
}

.product-title2 {
  @include setFont(Roboto, 1.75rem, $slate-grey, 600);

  margin-bottom: 1.1875rem;
  text-align: left;
  max-width: 25.1875rem;

  @include lt_lg {
    text-align: center;
    font-size: 1.375rem;
    margin-bottom: 1.4375rem;
    max-width: initial;
  }

  @include lt_md {
    margin-bottom: 0.75rem;
  }
}

.product-description {
  @include setFont(Roboto, 0.9375rem, $white, 300);

  padding: 0 0.625rem;
  max-width: 44.375rem;
  margin: 0 auto 2.75rem;
  line-height: 1.73;
  letter-spacing: 0.0125rem;
  text-align: center;

  @include lt_md {
    max-width: 40.625rem;
    font-size: 0.875rem;
    margin: 1.25rem auto 2.9375rem;
  }

  @include lt_sm {
    font-size: 0.9375rem;
  }
}

.product-description2 {
  @include setFont(Roboto, 1rem, $steel);

  line-height: 1.75;
  text-align: left;

  @include lt_lg {
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.7;
  }
}

.pricing-description {
  @include setFont(Roboto, 0.75rem, $font-light-desc);

  margin: 0 0 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.00625rem;
}

.result-content-title {
  @include setFont(Roboto, 1.125rem, $dark, 400);

  letter-spacing: 0.0125rem;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 0.9375rem;
}

.result-content-caption {
  @include setFont(Roboto, 0.75rem, $font-light-desc, 400);

  max-width: 31.3125rem;
  margin: 0 auto 1.875rem;
  letter-spacing: 0.00625rem;
  text-align: center;
  line-height: 1.5;
}

.free-title {
  @include setFont('Open Sans', 1.875rem, $warm-grey-seven, 700);

  letter-spacing: 0.01875rem;
  margin: 0;
  text-transform: uppercase;
  line-height: 1.4;
}

.verify-title {
  @include setFont(Roboto, 0.875rem, $label-font);

  margin: 0 0 0.625rem;
  line-height: 1.8;
}

.verify-describe {
  @include setFont('Open Sans', 0.8125rem, $font-desc);

  margin: 0;
  line-height: 1.5;

  strong {
    font-style: italic;
  }
}

.add-website-type {
  @include setFont(Roboto, 0.8125rem, $font-light-desc, 400);

  line-height: 2.15;
  letter-spacing: 0.00625rem;
  margin: 0 0 1.375rem;
}

.choose-type-description {
  @include setFont(Roboto, 0.75rem, $brownish-grey-three, 400);

  margin: 1rem 0 0.625rem;
  line-height: 1.5;
}

.choose-type-recommended {
  @include setFont(Roboto, 0.6875rem, $green-teal);

  margin: 0.1875rem 0 -0.9375rem 2.0625rem;
}

.current-plan {
  @include setFont('Open Sans', 0.75rem, $greyish-five, 400);

  line-height: 1.67;
  letter-spacing: 0.00625rem;
  font-style: italic;
  position: absolute;
  top: 1.5625rem;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.view-description {
  @include setFont(Roboto, 0.75rem, $warm-grey-ten, 400);

  line-height: 1.5;
  letter-spacing: 0.00625rem;
  margin: 0 auto 1.5rem;

  @include lt_sm {
    padding: 0 17.5%;
  }
}

.step-title {
  @include setFont(Roboto, 1.125rem, $dark, 400);

  margin: 1.875rem 0 1.25rem;
}

.desc-step-title {
  @include setFont(Roboto, 0.875rem, $font-desc, 400);

  margin: 0 0 0.625rem;
}

.desc-step {
  @include setFont(Roboto, 0.875rem, $font-desc, 400);

  margin: 0;
  line-height: 1.6;

  &:last-of-type {
    margin: 0 0 1.875rem;
  }
}

.clickwrap-option {
  @include setFont(Roboto, 0.8125rem, $font-desc, 400);

  display: inline-block;
}

.clickwrap-reason-content {
  @include setFont(Roboto, 0.8125rem, $font-desc, 400);

  margin: 0;
  line-height: 1.5;
}

.checkout-summary {
  @include setFont('Open Sans', 0.9375rem, $label-font, 700);

  margin: 0 0 0.5625rem;
}

.explore-title {
  @include setFont(Roboto, 1.4375rem, $charcoal-grey-two, 600);

  text-transform: uppercase;
  margin: 0 0 1.3125rem;

  @include lt_md {
    font-size: 1.25rem;
  }

  @include lt_sm {
    display: none;
  }
}

.attorney-title {
  @include setFont(Roboto, 1.75rem, $slate-grey, 600);

  margin: 4.75rem 0 3.8125rem;
  padding: 0 0.625rem;

  @include lt_md {
    font-size: 1.375rem;
    margin: 3.5rem 0 2.0625rem;
  }

  @include lt_sm {
    font-size: 1.375rem;
    margin: 0 0 2.125rem;
  }
}

.attoryney-desc {
  @include setFont(Roboto, 1rem, $steel, 400);

  line-height: 1.8;

  @include lt_md {
    font-size: 0.875rem;
    line-height: 1.79;
  }
}

.product-feature-title {
  @include setFont(Roboto, 1.75rem, $white);

  line-height: 1.4;
  margin: 0 0 0.8125rem;

  @include lt_sm {
    text-align: center;
    margin: 0 1.6875rem 0.625rem;
  }
}

.product-feature-desp {
  @include setFont(Roboto, 1rem, $white, 400);

  line-height: 1.7;
  margin: 0 0 1.625rem;

  @include lt_sm {
    text-align: center;
  }
}

.product-dashboard-link {
  margin-right: 0.8125rem;
}

.add-website-description {
  @include setFont(Roboto, 0.75rem, $font-light-desc, 400);
}

.consent-title {
  @include setFont(Roboto, 0.8125rem, $charcoal-grey-four, 500);

  display: block;
  margin: 0 0 0.625rem;
}

.scroll-consent-desc {
  @include setFont(Roboto, 0.75rem, $placeholder, 400);

  margin: 0;
  padding: 0 8.875rem 1.25rem 1.75rem;
  line-height: 1.4;
  position: relative;
}

.display-consent-banner-by-region-description {
  @include setFont(Roboto, 0.75rem, $placeholder, 400);

  margin: 0;
  padding: 0 8.875rem 2.5rem 1.75rem;
  line-height: 1.4;
  position: relative;
}

.live-preview-describe {
  @include setFont('Open Sans', 0.8125rem, $font-desc);

  margin: 0;
  line-height: 1.5;
}

.website-confirm {
  @include setFont('Open Sans', 0.75rem, $font-light-desc);

  letter-spacing: 0.00625rem;
  line-height: 1.5;
  display: inline-block;
  margin: 0 0 1.5rem;
  padding-left: 2.1875rem;
}

.tutorial-desp {
  @include setFont(Roboto, 0.875rem, $font-desc, 400);

  margin: 1.875rem 0 1.25rem;
}

.tutorial-content-title {
  @include setFont(Roboto, 1.4375rem, $white, 300);

  margin: 0 0 0.5625rem;
}

.tutorial-content-caption,
.tutorial-fail-caption,
.tutorial-success-caption {
  @include setFont(Roboto, 0.875rem, $white, 300);

  margin: 0;
  line-height: 1.7;
}

.tutorial-success-caption {
  color: $light-seafoam-green;

  + .tutorial-content-caption {
    margin: 0.3125rem 0 0;
  }
}

.tutorial-fail-caption {
  color: $pale;
}

.cookie-manager-desc {
  @include setFont(Roboto, 0.875rem, $bluey-grey-three, 400);

  line-height: 1.5;
  margin: 0;
}

.cookie-manager-desc-warn {
  @extend .cookie-manager-desc;

  font-weight: 500;
  color: $salmon-two;
}

.consent-collected-status {
  @include setFont(Roboto, 0.8125rem, $bluey-grey-three, 400 );

  display: flex;
  align-items: center;
  text-align: right;
  margin: 0;
  padding-right: 0.875rem;
  height: 2.5625rem;
  letter-spacing: 0.00625rem;
}

.website-install-banner-notice {
  @include setFont(Roboto, 0.875rem, $bluey-grey-three, 400);

  margin: 0 0 1.5625rem;

  & p {
    display: inline;
  }
}

.website-install-banner-link,
.website-exceeded-limitation-Link {
  color: $azure-two;
  text-decoration: underline;
  text-decoration-color: $azure-two;
}

.website-exceeded-limitation-notice {
  @include setFont(Roboto, 0.875rem, $bluey-grey-three, 500);

  margin: 1.25rem 0 0;

  & p {
    display: inline;
  }
}

.website-exceeded-limitation-warn {
  color: $salmon-two;
}

.website-report-title {
  @include setFont(Roboto, 1.125rem, $dark-blue-grey, 400);

  display: inline-block;
  line-height: 3.125rem;
  margin: 0;
}

.report-describe {
  @include setFont(Roboto, 0.875rem, $bluey-grey-three, normal);

  margin: 0.3125rem;
}

.unclassified-describe {
  @extend .report-describe;

  color: $yellow-orange;
  padding-right: 1.1875rem;
  margin-bottom: 1.1875rem;
  position: relative;
  left: 1.25rem;

  &::before {
    display: inline-block;
    content: '\200B';
    width: 1rem;
    height: 0.9375rem;
    background: url(images/orange-notice-mark.svg) center no-repeat;
    background-size: 1rem 0.9375rem;
    margin-right: 0.625rem;
    position: absolute;
    left: -1.5625rem;
  }
}

.report-save-change-tooltip {
  @include setFont(Roboto, 0.75rem, $blood-orange, normal);

  display: inline-block;
  max-width: 16.25rem;
  text-align: right;
  line-height: 1.5;
  letter-spacing: 0.00625rem;
  vertical-align: middle;
  margin: 0 0.9375rem;
}

.website-report-add-cookies {
  @include setFont(Roboto, 1.125rem, $dark, 400);

  letter-spacing: 0.0125rem;
  margin-bottom: 3.125rem;

  ~ form button {
    margin-right: 1.25rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.filter-new-desc {
  @include setFont(Roboto, 0.875rem, $bluey-grey-three, 500);

  letter-spacing: 0.00625rem;
  line-height: 1.5;
  display: inline-block;
  padding-left: 2.1875rem;
  margin: 0;
}

.domain-desp-title {
  @include setFont(Roboto, 1.125rem, $jeans-blue, 400);

  padding-bottom: 3.5rem;
  letter-spacing: 0.0125rem;
  margin: 0;
}

.domain-desp {
  @include setFont(Roboto, 0.875rem, $bluey-grey-three, 400);

  margin: 0 auto 1.5625rem;
  line-height: 1.5;
}

.preview-blocker-title {
  @include setFont(Roboto, 0.875rem, $dark-blue-grey, 500);

  letter-spacing: 0.0125rem;
  margin: 0 auto 0.625rem;
}

.preview-blocker-note {
  @include setFont(Open Sans, 0.75rem, $salmon-two, 700);

  letter-spacing: 0.00625rem;
  margin: 1.875rem auto 0.625rem;
}

.preview-blocker-description {
  @include setFont(Roboto, 0.75rem, $bluey-grey-three, normal);

  letter-spacing: 0.00625rem;
  line-height: 1.58;
}

.last-updated-title {
  margin: 0 0 1.5625rem;
}

.user-consent-logs-blocker-title {
  @include setFont(Roboto, 0.9375rem, $dark-blue-grey, 500);

  letter-spacing: 0.0125rem;
  margin: 0 0 1.25rem;
}

.user-consent-logs-tooltip-title {
  @include setFont(Roboto, 0.8125rem, $white, 700);

  margin: 0;
}

.user-consent-logs-tooltip {
  @include setFont(Roboto, 0.8125rem, $white, 400);

  margin: 0;
}

.cookie-scan-settings {
  @include setFont(Roboto, 0.9375rem, $jeans-blue);

  margin: 0;
}

.upgradeDescription {
  @include setFont(Roboto, 0.75rem, $salmon-two, bold);

  width: 14.1875rem;
  height: 3.1875rem;
  letter-spacing: 0.00625rem;
}

.subdomain-upgrade-description {
  @include setFont('Open Sans', 0.75rem, $salmon-two, bold);

  letter-spacing: 0.008125rem;
}

.manage-cookie-preference-preview {
  @include setFont('Roboto', 0.875rem, $bluey-grey-two, 500);

  width: 100%;
  text-align: center;
  letter-spacing: 0.0125rem;
}
